// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'
import controller from './application_controller'
import debounced from 'debounced'
import { Autocomplete } from 'stimulus-autocomplete'
//debounced.initialize()
// debounce only the input event and wait 100ms before dispatching
debounced.initialize({ ...debounced.events, input: { wait: 1000 } })

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
StimulusReflex.initialize(application, { consumer, controller, debug: false })

application.register('autocomplete', Autocomplete)
