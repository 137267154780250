import ApplicationController from './application_controller'

export default class extends ApplicationController {
    static targets = ['query', 'activity', 'count', 'list']


    connect () {
        console.log("connec book_search_controller")
        super.connect()
        // add your code here, if applicable
    }

    beforePerform (element, reflex) {
        this.activityTarget.hidden = false
        this.countTarget.hidden = true
    }

    perform (event) {
        event.preventDefault()
        console.log("perform called in book_search_controller")
        this.stimulate('BookSearchReflex#perform', this.queryTarget.value)
    }
}
