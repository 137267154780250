import ApplicationController from './application_controller'

export default class extends ApplicationController {
    static targets = ['inventoryDateQuery', 'locationQuery', 'activity', 'count', 'list']

    beforePerform (element, reflex) {
        this.activityTarget.hidden = false
        this.countTarget.hidden = true
    }

    changeLocation(event) {
        this.locationQueryTarget.value=event.target.dataset.locationNameValue
        this.stimulate('LocationSearchReflex#perform',this.inventoryDateQueryTarget.value ,event.target.dataset.locationNameValue)
    }

    edit(event) {
        console.log("edit_event", event.target.dataset)
        const party_id = event.target.dataset.partyid
        const product_id = event.target.dataset.productid
        const location_id = event.target.dataset.locationid
        this.stimulate('LocationSearchReflex#edit', this.inventoryDateQueryTarget.value, party_id, product_id, location_id, this.locationQueryTarget.value)
    }

    update(event) {
        console.log("update_event", event.target.dataset)
        const party_id = event.target.dataset.partyid
        const product_id = event.target.dataset.productid
        const location_id = event.target.dataset.locationid
        const value = event.target.value
        this.stimulate('LocationSearchReflex#update', this.inventoryDateQueryTarget.value, party_id, product_id, location_id, value, this.locationQueryTarget.value)
    }

    cancelEdit (event) {
        if (event.type === 'keyup' && !['Escape', 'Esc'].includes(event.key)) return
        this.stimulate('LocationSearchReflex#cancel_edit',this.inventoryDateQueryTarget.value ,this.locationQueryTarget.value)
    }

    perform (event) {
        console.log("perform_event", event.target.dataset)
        event.preventDefault()
        this.stimulate('LocationSearchReflex#perform',this.inventoryDateQueryTarget.value ,this.locationQueryTarget.value)
    }
}
